import React from "react"

export default function Overlay({ isTouch, setType }) {
    const current = new Date()
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`

    return (
        <div className="overlay">
            <div className="overlay__logo">
                <svg version="1.1" id="logo-image" width="75" height="50" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 82.2 38.7" fill="#29f29f">
                    <path className="st0" d="M24.1,32.7H7.5c-0.7,0-1.3-0.7-1.2-1.4c0.6-2.8,2.5-4.5,5.9-6.3l3.7-2.1c6.1-3.3,9-7,9-12.2 C24.8,4.5,20,0,12.6,0C4.9,0,0.4,4,0,10.9c0,0.7,0.5,1.3,1.2,1.3h3.1c0.6,0,1.1-0.5,1.2-1.1c0.4-4.4,3-6.3,7-6.3 c4.3,0,6.7,2.5,6.7,6.1c0,3.6-1.9,5.7-6.2,8l-4.2,2.3C1.4,25,0.3,30.6,0.1,35.2L0,36.3c0,0.7,0.5,1.2,1.2,1.2h22.9 c0.6,0,1.2-0.5,1.2-1.2v-2.4C25.3,33.3,24.8,32.7,24.1,32.7z"></path>
                    <path className="st0" d="M50.4,2.9c-2.1-1.6-5-2.6-8.4-2.6c-7.9,0-13.1,5.3-13.1,12.6c0,6.7,4.5,12.3,12.5,12.3c2.7,0,5.2-1,7.1-2.6 c0.8-0.7,2,0,1.9,1.1c-0.7,7-3.4,10.5-8.4,10.5c-3.7,0-6.2-1.6-7.2-4.7c-0.2-0.5-0.6-0.8-1.1-0.8h-2.9c-0.8,0-1.4,0.8-1.1,1.5 c1.5,5.4,6.1,8.5,12.4,8.5c9.2,0,13.8-7.5,13.8-19.5C56,12.4,54.9,6.4,50.4,2.9z M42,20.6c-4.4,0-7.6-3.2-7.6-7.8 C34.5,8.3,37.6,5,42,5c4.4,0,7.6,3.1,7.6,7.8C49.7,17.5,46.5,20.6,42,20.6z"></path>
                    <path className="st0" d="M76.1,20c-0.5-0.5-0.5-1.2,0-1.7l5.8-5.8c0.5-0.5,0.5-1.2,0-1.7l-1.5-1.5c-0.5-0.5-1.2-0.5-1.7,0l-5.8,5.8 c-0.5,0.5-1.2,0.5-1.7,0l-5.9-5.8c-0.5-0.5-1.2-0.5-1.7,0l-1.5,1.5c-0.5,0.5-0.5,1.2,0,1.7l5.8,5.8c0.5,0.5,0.5,1.2,0,1.7 l-5.8,5.8c-0.5,0.5-0.5,1.2,0,1.7l1.5,1.5c0.5,0.5,1.2,0.5,1.7,0l5.9-5.8c0.5-0.5,1.2-0.5,1.7,0l5.8,5.8c0.5,0.5,1.2,0.5,1.7,0 l1.5-1.5c0.5-0.5,0.5-1.2,0-1.7L76.1,20z"></path>
                </svg>
            </div>
            <div className="overlay__link">
                <a href="https://29x.studio" className="underline" onMouseEnter={(e) => setType("hovered-dot")} onMouseLeave={(e) => setType("")}>
                    <span>Visit site</span>
                    <svg viewBox="0 0 13 20">
                        <polyline points="0.5 19.5 3 19.5 12.5 10 3 0.5" />
                    </svg>
                </a>
            </div>
            {!isTouch && (
                <p href="https://29x.studio">
                    {date}
                    <br />
                    Developer playground
                </p>
            )}
        </div>
    )
}
