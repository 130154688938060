import React from "react"

// Motion Libs
import { motion } from "framer-motion"

const Loading = ({ setLoading }) => {
    const easing = [0.68, -0.01, 0.3, 1]

    const main = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
        exit: {
            opacity: 0,
            y: -10,
            transition: {
                delay: 1.8,
                duration: 0.7,
            },
        },
    }

    const animLogo = {
        hidden: {
            opacity: 0,
            y: 10,
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                delay: 0.7,
                duration: 0.7,
                ease: easing,
            },
        },
    }

    const animX = {
        hidden: { rotate: 0 },
        visible: { rotate: 360, transition: { delay: 1.5, duration: 0.8, ease: easing } },
    }

    const handleLoading = () => {
        setLoading(false)
    }

    return (
        <motion.main className="loader" variants={main} onAnimationComplete={handleLoading} initial="hidden" animate="visible" exit="exit" key="loading-key">
            <div className="loader-inner">
                <div className="loading-text">
                    <motion.svg key="loader-svg" initial="hidden" animate="visible" exit="exit" variants={animLogo} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 82.2 79.08">
                        <path
                            fill="#29F29F"
                            d="M24.1,52.79H7.46c-0.74,0-1.32-0.68-1.16-1.4c0.62-2.78,2.54-4.46,5.88-6.33l3.66-2.06
			c6.13-3.3,8.97-7.01,8.97-12.22c0-6.24-4.79-10.72-12.22-10.72c-7.73,0-12.16,4.04-12.59,10.9c-0.04,0.68,0.5,1.26,1.18,1.26h3.07
			c0.61,0,1.12-0.47,1.17-1.07c0.36-4.43,3-6.3,7.02-6.3c4.33,0,6.7,2.53,6.7,6.13c0,3.56-1.86,5.72-6.18,8.04l-4.17,2.32
			c-7.37,3.76-8.45,9.28-8.71,13.92L0,56.33C-0.04,57,0.5,57.58,1.18,57.58H24.1c0.65,0,1.18-0.53,1.18-1.18v-2.44
			C25.28,53.31,24.75,52.79,24.1,52.79z"
                        />
                        <path
                            fill="#29F29F"
                            d="M50.38,23c-2.11-1.65-5-2.63-8.35-2.63c-7.94,0-13.09,5.31-13.09,12.58c0,6.7,4.48,12.27,12.52,12.27
			c2.67,0,5.18-0.96,7.13-2.58c0.81-0.67,2.01,0.02,1.9,1.07c-0.72,7.01-3.43,10.48-8.36,10.48c-3.66,0-6.22-1.58-7.17-4.65
			c-0.15-0.49-0.61-0.81-1.11-0.81h-2.92c-0.78,0-1.36,0.75-1.14,1.49c1.54,5.38,6.05,8.5,12.39,8.5c9.17,0,13.81-7.53,13.81-19.48
			C55.99,32.48,54.86,26.5,50.38,23z M42.03,40.68c-4.43,0-7.58-3.25-7.58-7.78c0-4.59,3.14-7.83,7.58-7.83
			c4.43,0,7.63,3.14,7.63,7.83C49.65,37.53,46.46,40.68,42.03,40.68z"
                        />
                        <motion.path
                            key="loader-path"
                            variants={animX}
                            fill="#29F29F"
                            d="M76.05,40.06c-0.46-0.46-0.46-1.21,0-1.67l5.81-5.81c0.46-0.46,0.46-1.21,0-1.67l-1.53-1.53
			c-0.46-0.46-1.21-0.46-1.67,0l-5.81,5.81c-0.46,0.46-1.2,0.46-1.66,0l-5.86-5.82c-0.46-0.46-1.2-0.46-1.66,0l-1.53,1.53
			c-0.46,0.46-0.46,1.21,0,1.67l5.81,5.81c0.46,0.46,0.46,1.21,0,1.67l-5.81,5.81c-0.46,0.46-0.46,1.21,0,1.67l1.53,1.53
			c0.46,0.46,1.2,0.46,1.66,0l5.86-5.82c0.46-0.46,1.2-0.46,1.66,0l5.81,5.81c0.46,0.46,1.21,0.46,1.67,0l1.53-1.53
			c0.46-0.46,0.46-1.21,0-1.67L76.05,40.06z"
                        />
                    </motion.svg>
                </div>
            </div>
        </motion.main>
    )
}

export default Loading
