// Base
import React, { useEffect, useState } from "react"

// Libs
import { useThree } from "@react-three/fiber"
import { ScrollControls, Scroll } from "@react-three/drei"

// Components
import { Minimap } from "./Minimap"
import Item from "./Item"
import useMousePosition from "../util/useMousePosition"

function Items({ w = 0.7, gap = 0.15, isTouch, type, setType, isDown }) {
    const [projects, setProjects] = useState([])
    const [slideOpen, setSlideOpen] = useState(null)

    const { width } = useThree((state) => state.viewport)
    const { x, y } = useMousePosition()
    const xW = w + gap
    let projectsLength = projects.length

    useEffect(() => {
        const getFiles = async () => {
            await fetch("https://playground.29x.dk/directories.php")
                .then((res) => res.json())
                .then((data) => {
                    setProjects(data)
                })
        }
        getFiles()
        return () => {
            getFiles()
        }
    }, [setProjects])

    // Udregning til Depth effekt
    const range = 0.7
    const calcValue = (a, b) => ((a / b) * range - range / 2).toFixed(1)
    let yValue = calcValue(y, window.innerHeight)
    let xValue = calcValue(x, window.innerWidth)

    return (
        <ScrollControls horizontal enabled={slideOpen === true ? false : true} damping={10} pages={(width - xW + projectsLength * xW) / width}>
            <Minimap isTouch={isTouch} projects={projects} gap={xW} width={w} />
            <Scroll>
                {projects.map((item, i) => {
                    return <Item isDown={isDown} type={type} setType={setType} slideOpen={slideOpen} setSlideOpen={setSlideOpen} isTouch={isTouch} projects={item} link={item.link} url={item.image} title={item.name} projectsLength={projectsLength} key={i} index={i} rotation={[yValue, xValue, 0]} position={[i * xW, 0, 0]} scale={[w, 4, 1]} />
                })}
            </Scroll>
        </ScrollControls>
    )
}
export default Items
