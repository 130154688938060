import React from "react"
import useMousePosition from "../util/useMousePosition"

export default function Cursor({ type }) {
    const { x, y } = useMousePosition()
    return (
        <>
            <div style={{ left: `${x}px`, top: `${y}px` }} className={`ring ${type}`}></div>
            <div className={`open ${type}`} style={{ left: `${x}px`, top: `${y}px` }}>
                Open
            </div>
            <div className={`dot ${type}`} style={{ left: `${x}px`, top: `${y}px` }}></div>
        </>
    )
}
