// Base
import React, { useRef, useState, useEffect } from "react"

// Libs
import * as THREE from "three"
import { useFrame } from "@react-three/fiber"
import { Image, useScroll, Text } from "@react-three/drei"
import { useSnapshot } from "valtio"
import { animated, useSpring } from "@react-spring/three"

// Components
import { state, damp } from "../util/util"

const AnimatedText = animated(Text)

function Item({ index, position, rotation, scale, c = new THREE.Color(), projectsLength, title, url, link, isTouch, setSlideOpen, setType, isDown }) {
    const ref = useRef()
    const text = useRef()
    const linkText = useRef()
    const scroll = useScroll()
    const { clicked } = useSnapshot(state)
    const [hovered, hover] = useState(false)
    const [isActive, setIsActive] = useState(false)

    const spring = useSpring({
        scale: isActive === index ? [5, 5, 5] : [0, 0, 0],
        config: {},
    })

    const springLink = useSpring({
        scale: isActive === index ? [2, 2, 2] : [0, 0, 0],
        config: {},
    })

    const handleStates = () => {
        setIsActive(false)
        setSlideOpen(false)
    }

    useEffect(() => {
        let Camera = document.querySelector(".scroll-canvas div div")

        if (clicked === index) {
            Camera.scrollLeft = 100 * index + 70
        }

        return () => {}
    }, [clicked])

    const handleClick = (e, index) => {
        state.clicked = index === clicked ? null : index

        if (clicked === index) {
            setIsActive(false)
            setSlideOpen(false)
        } else {
            setIsActive(index)
            setSlideOpen(true)
        }
    }

    const handleLink = () => {
        setTimeout(() => {
            window.open(link, "_self")
        }, 200)
    }

    const over = () => {
        hover(true)
        setType("hovered-open")
    }
    const out = () => {
        hover(false)
        setType("")
    }

    useFrame((state, delta, e) => {
        const y = scroll.curve(index / projectsLength - 1.5 / projectsLength, 4 / projectsLength)
        ref.current.material.scale[1] = ref.current.scale.y = damp(ref.current.scale.y, clicked === index ? 5 : 4 + y, 8, delta)

        if (isTouch) {
            ref.current.material.scale[0] = ref.current.scale.x = damp(ref.current.scale.x, clicked === index ? 4.2 : scale[0], 6, delta)
        } else {
            ref.current.material.scale[0] = ref.current.scale.x = damp(ref.current.scale.x, clicked === index ? 4.7 : scale[0], 6, delta)
        }

        if (clicked !== null && index < clicked) {
            ref.current.position.x = damp(ref.current.position.x, position[0] - 2, 6, delta)
            setIsActive(false)
        } else {
            text.current.position.z = damp(text.current.position.z, 0, 6, delta)
            linkText.current.position.z = damp(linkText.current.position.z, 0, 6, delta)
        }
        if (clicked !== null && index > clicked) {
            ref.current.position.x = damp(ref.current.position.x, position[0] + 2, 6, delta)
            setIsActive(false)
        } else {
            text.current.position.z = damp(text.current.position.z, 0, 6, delta)
            linkText.current.position.z = damp(linkText.current.position.z, 0, 6, delta)
        }
        if (clicked === null || clicked === index) {
            ref.current.position.x = damp(ref.current.position.x, position[0], 6, delta)
        } else {
            text.current.position.z = damp(text.current.position.z, 0, 6, delta)
            linkText.current.position.z = damp(linkText.current.position.z, 0, 6, delta)
        }

        if (clicked === index && !isTouch) {
            // Rotation af billede når åbent
            ref.current.rotation.x = damp(ref.current.rotation.x, rotation[0], 6, delta)
            ref.current.rotation.y = damp(ref.current.rotation.y, rotation[1], 6, delta)
            text.current.rotation.x = damp(text.current.rotation.x, rotation[0], 6, delta)
            text.current.rotation.y = damp(text.current.rotation.y, rotation[1], 6, delta)
            linkText.current.rotation.x = damp(linkText.current.rotation.x, rotation[0], 6, delta)
            linkText.current.rotation.y = damp(linkText.current.rotation.y, rotation[1], 6, delta)

            // Move text tættere når åbent
            text.current.position.z = damp(text.current.position.z, position[1] + 1, 6, delta)
            linkText.current.position.z = damp(linkText.current.position.z, position[1] + 1, 6, delta)
        } else {
            // Reset af billede når lukket
            ref.current.rotation.x = damp(ref.current.rotation.x, 0, 6, delta)
            ref.current.rotation.y = damp(ref.current.rotation.y, 0, 6, delta)
            text.current.rotation.x = damp(text.current.rotation.x, 0, 6, delta)
            text.current.rotation.y = damp(text.current.rotation.y, 0, 6, delta)
            linkText.current.rotation.x = damp(linkText.current.rotation.x, 0, 6, delta)
            linkText.current.rotation.y = damp(linkText.current.rotation.y, 0, 6, delta)

            // Move text tættere når åbent
            text.current.position.z = damp(text.current.position.z, 0, 6, delta)
            linkText.current.position.z = damp(linkText.current.position.z, 0, 6, delta)
        }

        ref.current.crossOrigin = true

        ref.current.material.grayscale = damp(ref.current.material.grayscale, hovered || clicked === index ? 0 : Math.max(0, 1 - y), 1, delta)
        ref.current.material.color.lerp(c.set(hovered || clicked === index ? "white" : "#29f29f"), hovered ? 0.1 : 0.5)
    })

    return (
        <group className="playground-item">
            <Image ref={ref} url={url} position={position} scale={scale} onClick={(e) => handleClick(e, index)} onPointerOver={over} onPointerOut={out} onPointerMissed={() => handleStates()} />
            <group>
                <AnimatedText color="#000" ref={text} position={position} scale={scale} {...spring}>
                    {title}
                </AnimatedText>
                <group>
                    <AnimatedText color="#000" ref={linkText} position={[position[0], -0.5, 0]} scale={scale} onClick={(e) => handleLink()} {...springLink}>
                        See project
                    </AnimatedText>
                </group>
            </group>
        </group>
    )
}

export default Item
