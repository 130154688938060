import "./styles/style.scss"

// Base
import React, { useState, useEffect } from "react"

// Libs
import { AnimatePresence } from "framer-motion"
import MouseContextProvider from "./util/mouse-context"

// Components
import Cursor from "./components/Cursor"
import Noise from "./components/Noise"
import Playground from "./components/Playground"
import Loading from "./components/Loading"

function App() {
    const [isTouch, setIsTouch] = useState(null)
    const [loading, setLoading] = useState(true)
    const [consoleText, setConsoleText] = useState(null)
    const [cursorType, setCursorType] = useState(null)

    useEffect(() => {
        const touchCheck = () => {
            if ("ontouchstart" in document.querySelector("body")) {
                setIsTouch(true)
            } else {
                setIsTouch(false)
            }
        }

        const consoleCopy = () => {
            if (navigator.userAgent.toLowerCase().indexOf("chrome") > -1) {
                let args = ["\n %c Playground by 29x.studio %c %c %c https://29x.studio %c %c \n", "color: #383635; background: #29f29f; padding:5px 0;", "background: #383635; padding:5px 0;", "background: #131419; padding:5px 0;", "color: #fff; background: #1c1c1c; padding:5px 0;", "background: #fff; padding:5px 0;", "color: #e43333; background: #fff; padding:5px 0;"]
                window.console.log.apply(console, args)
                setConsoleText(true)
            } else if (window.console) {
                window.console.log("Playground by 29x.studio - https://29x.studio")
                setConsoleText(true)
            }
        }
        loading ? document.querySelector("body").classList.add("loading") : document.querySelector("body").classList.remove("loading")

        return () => {
            touchCheck()
            consoleCopy()
        }
    }, [consoleText, isTouch, loading])

    return (
        <div className="App">
            {isTouch === false && (
                <>
                    <Cursor type={cursorType} />
                    <Noise />
                </>
            )}

            <MouseContextProvider>
                <AnimatePresence mode="wait">
                    {loading ? (
                        <Loading setLoading={setLoading} />
                    ) : (
                        <div className="inner" canonical="playground" key="playground">
                            <Playground isTouch={isTouch} type={cursorType} setType={setCursorType} />
                        </div>
                    )}
                </AnimatePresence>
            </MouseContextProvider>
        </div>
    )
}

export default App
