// Base
import { useRef } from "react"

// Libs
import { useFrame, useThree } from "@react-three/fiber"
import { useScroll } from "@react-three/drei"
import { useSnapshot } from "valtio"

// Components
import { damp, state } from "../util/util"

export function Minimap({ projects, xW, width }) {
    const ref = useRef()
    const scroll = useScroll()
    const { height } = useThree((state) => state.viewport)
    const { clicked } = useSnapshot(state)
    useFrame((state, delta, index) => {
        ref.current.children.forEach((child, index) => {
            const y = scroll.curve(index / projects.length - 1.5 / projects.length, 4 / projects.length)
            child.scale.y = damp(child.scale.y, 0.1 + y / 6, 8, delta)

            if (clicked === index) {
                child.scale.x = damp(child.scale.x, 0.02, 6, delta)
            } else {
                child.scale.x = damp(child.scale.x, 0.01, 6, delta)
            }
        })
    })

    return (
        <group ref={ref}>
            {projects.map((_, i) => {
                return (
                    <mesh key={i} scale={[0.01, width, 1]} position={[i * 0.06 - projects.length * 0.03, -height / 2 + 0.6, 0]}>
                        <planeBufferGeometry />
                        <meshBasicMaterial color="#29f29f" />
                    </mesh>
                )
            })}
        </group>
    )
}
