import { useEffect, useState } from "react";

export default function useMousePosition() {
    const [mousePosition, setMousePosition] = useState({ x: null, y: null });

    const mouseMoveHandler = (event) => {
        const { clientX, clientY } = event;
        setMousePosition({ x: clientX, y: clientY });
    };

    const cursorUpdate = () => {
        
        document.addEventListener("mousemove", mouseMoveHandler);
    }

    const cursorRemove = () => {
        document.removeEventListener("mousemove", mouseMoveHandler);
    }

    useEffect(() => {
        requestAnimationFrame(() => cursorUpdate());
        return () => {
            cancelAnimationFrame(() => cursorRemove());
        };
    }, [cursorUpdate, cursorRemove]);

    return mousePosition;
}
