// Base
import React, { useEffect, useState } from "react"

// Libs
import { Canvas } from "@react-three/fiber"
import { useSnapshot } from "valtio"

// Components
import { state } from "../util/util"
import Overlay from "./Overlay"
import Items from "./ItemScroll"
import { motion } from "framer-motion"

export default function Playground({ isTouch, type, setType }) {
    const [isDown, setIsDown] = useState(false)
    const [startX, setStartX] = useState(null)
    const [scrollLeft, setScrollLeft] = useState(null)

    const animItems = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { delay: 0.6 } },
    }

    const handleDrag = (e) => {
        let Dragger = document.querySelector(".scroll-canvas div div")

        if (e.type === "mousedown") {
            setIsDown(true)
            setStartX(e.pageX - Dragger.offsetLeft)
            setScrollLeft(Dragger.scrollLeft)
        }

        if (e.type === "mouseup") {
            setIsDown(false)
        }

        if (e.type === "mousemove") {
            if (!isDown) return
            e.preventDefault()
            const x = e.pageX - Dragger.offsetLeft
            const walk = (x - startX) * 1
            Dragger.scrollLeft = scrollLeft - walk
        }
    }

    return (
        <div className="playground">
            <motion.main className="container" variants={animItems} initial="hidden" animate="visible">
                <Canvas onMouseDown={(e) => handleDrag(e)} onMouseMove={(e) => handleDrag(e)} onMouseUp={(e) => handleDrag(e)} gl={{ antialias: false }} dpr={[1, 1.5]} onPointerMissed={() => (state.clicked = null)} className="scroll-canvas">
                    <Items isDown={isDown} isTouch={isTouch} type={type} setType={setType} />
                </Canvas>
                <Overlay setType={setType} isTouch={isTouch} />
            </motion.main>
        </div>
    )
}
